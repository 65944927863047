<template>
  <div class="content">
    <PageHeader :title="$t('new-email-component.title')" />
    <div class="page-content container-fluid">
      <div class="card card-tabs">
        <div class="card-header">
          <ul class="nav nav-tabs primary-tabs">
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link active"
                v-tab="'simple'"
                data-toggle="tab"
                aria-expanded="true"
                @click="changeType('simple')"
                >{{$t('sms.send-msg.tabs.simple.title')}}</a
              >
            </li>
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link"
                v-tab="'contact'"
                aria-expanded="true"
                @click="changeType('contacts')"
                >{{$tc('sms.send-msg.tabs.contact.title', 2)}}</a
              >
            </li>
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link"
                v-tab="'group'"
                aria-expanded="true"
                @click="changeType('groups')"
                >{{$t('sms.send-msg.tabs.groups.title')}}</a
              >
            </li>
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link"
                v-tab="'file'"
                aria-expanded="true"
                @click="changeType('file')"
                >{{$t('multi-upload.lbl-send-arch')}}</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <form action="#" class="form-horizontal">
            <div class="form-body">
              <div class="row">
                <div class="col-lg-8 col-md-12 col-sm-12">
                  <div class="tab-content">
                    <div class="tab-pane active" id="simple"></div>
                    <div class="tab-pane" id="contact">
                      <div class="form-group row">
                        <label class="control-label text-left col-4"
                          >{{$tc('sms.send-msg.tabs.contact.title', 2)}}</label
                        >
                        <div class="col-md-12 block-el">
                          <div>
                            <button
                              class="btn btn-success"
                              type="button"
                              v-modal="{ target: 'contact-send-modal' }"
                            >
                              {{$t('sms.send-msg.tabs.contact.lbl-add-contact')}}
                            </button>
                            <hr />
                            <b-collapse id="collapse-1" visible class="mt-2">
                              <b-card class="send-group-card">
                                <div
                                  class="text-center table-placeholder"
                                  v-if="contacts.length == 0"
                                >
                                  <i class="fas fa-user font-size-80"></i>
                                  <h5 class="card-title m-t-20">
                                    {{$t('sms.send-msg.tabs.contact.lbl-none-contact')}}
                                  </h5>
                                </div>
                                <p class="card-text" v-if="contacts.length > 0">
                                  {{$t('sms.send-msg.tabs.contact.lbl-none-contact')}} ({{ contacts.length }})
                                </p>
                                <ul
                                  class="ul-list-groups"
                                  v-if="contacts.length > 0"
                                >
                                  <li
                                    v-for="(contact, i) in contacts"
                                    :key="contact.id"
                                  >
                                    <div
                                      class="
                                        alert
                                        alert-secondary
                                        alert-outline
                                        alert-dismissible
                                        fade
                                        show
                                      "
                                      role="alert"
                                    >
                                      {{ contact.first_name }} /
                                      {{ contact.email }}
                                      <button
                                        type="button"
                                        class="close"
                                        @click="removeContact(i)"
                                      >
                                        <span
                                          aria-hidden="true"
                                          class="fas fa-times"
                                        ></span>
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </b-card>
                            </b-collapse>
                          </div>
                          <!-- <group-table isSendPage="true" />-->
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane" id="group">
                      <div class="form-group row">
                        <label class="control-label text-left col-12"
                          >{{$t('sms.send-msg.tabs.groups.title')}}</label
                        >
                        <div class="col-md-12 block-el">
                          <div>
                            <button
                              class="btn btn-success"
                              type="button"
                              v-modal="{ target: 'group-send-modal' }"
                            >
                              {{$t('sms.send-msg.tabs.groups.lbl-add-group')}}
                            </button>
                            <hr />
                            <b-collapse id="collapse-1" visible class="mt-2">
                              <b-card class="send-group-card">
                                <div
                                  class="text-center table-placeholder"
                                  v-if="groups.length == 0"
                                >
                                  <i class="fas fa-users font-size-80"></i>
                                  <h5 class="card-title m-t-20">
                                    {{$t('sms.send-msg.tabs.groups.lbl-none-group')}}
                                  </h5>
                                </div>
                                <p class="card-text" v-if="groups.length > 0">
                                  {{$t('sms.send-msg.tabs.groups.lbl-added-groups')}} ({{ groups.length }})
                                </p>
                                <ul
                                  class="ul-list-groups"
                                  v-if="groups.length > 0"
                                >
                                  <li
                                    v-for="(group, i) in groups"
                                    :key="group.id"
                                  >
                                    <div
                                      class="
                                        alert
                                        alert-secondary
                                        alert-outline
                                        alert-dismissible
                                        fade
                                        show
                                      "
                                      role="alert"
                                    >
                                      {{ group.name }}
                                      <button
                                        type="button"
                                        class="close"
                                        @click="removeGroup(i)"
                                      >
                                        <span
                                          aria-hidden="true"
                                          class="fas fa-times"
                                        ></span>
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </b-card>
                            </b-collapse>
                          </div>
                          <!-- <group-table isSendPage="true" />-->
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="file"></div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group row">
                          <label class="control-label text-left col-3">
                            {{$t('usage.lbl-product')}}
                          </label>
                          <div class="col-md-9">
                            <select-input
                              label="name"
                              :reduce="(product) => product.id"
                              v-model="form.productId"
                              :options="products"
                              :clearable="false"
                              v-on:option:selected="changeProduct"
                            />
                          </div>
                        </div>
                        <div
                          v-if="form.type != 'simple'"
                          class="form-group row"
                        >
                          <label class="control-label text-left col-12"
                            >{{$t('sms.infos.filters.field.lbl-campaign')}}</label
                          >
                          <div class="col-md-12 col-sm-12">
                            <input v-model="form.name" class="form-control" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            class="
                              control-label
                              m-text-left
                              col-lg-12 col-md-12
                            "
                            >{{$t('generic-str.subject')}}</label
                          >
                          <div class="col-md-12 col-sm-12 m-left relative">
                            <input
                              v-model="form.subject"
                              class="form-control"
                              maxlength="80"
                            />
                            <Emojis
                              v-on:select="putEmoji"
                              title=""
                              class="emoji-subject"
                            />
                            <ul
                              class="actions text-right actions-var subjectvar"
                              style="position: absolute !important"
                              v-if="form.type != 'simple'"
                            >
                              <li class="dropdown">
                                <a
                                  role="button"
                                  class="emoji-color btn-add-var-subject"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i
                                    class="
                                      far
                                      fa-bookmark
                                      actions-icon
                                      emoji-color
                                    "
                                  ></i>
                                </a>
                                <div
                                  class="
                                    dropdown-menu
                                    var-add-action-subject
                                    dropdown-menu-right
                                    animation
                                  "
                                  v-bind:class="{ show: addVarsSubject }"
                                  x-placement="bottom-end"
                                  style="
                                    position: absolute;
                                    transform: translate3d(-232px, 20px, 0px);
                                    top: 0px;
                                    left: 0px;
                                    will-change: transform;
                                  "
                                >
                                  <a
                                    v-for="varSMS in vars"
                                    v-bind:key="varSMS.value"
                                    class="dropdown-item var-add-action-item"
                                    @click="addVar(varSMS.value, 'subject')"
                                    >{{ varSMS.name }}</a
                                  >
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          v-if="form.type == 'simple'"
                        >
                          <label
                            class="
                              control-label
                              m-text-left
                              col-lg-12 col-md-12
                            "
                            >{{$t('generic-str.lbl-recipient')}}</label
                          >
                          <div class="col-md-12 col-sm-12">
                            <vue-tags-input
                              v-model="form.to_t"
                              :tags="tags"
                              :max-tags="10"
                              @tags-changed="(newTags) => (tags = newTags)"
                              :placeholder="'Ex: marcos@gmail.com,maria@dominio.com'"
                              :separators="[';', ',']"
                              :add-on-key="[13, ':', ';', ',']"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            class="
                              control-label
                              m-text-left
                              col-lg-12 col-md-12
                            "
                            >{{$t('generic-str.lbl-sender')}}</label
                          >
                          <div class="col-12">
                            <div v-if="domains.length" class="row">
                              <div class="col-8">
                                <input
                                  v-model="form.from_t"
                                  class="form-control"
                                  @keyup="removeSpaces"
                                />
                              </div>
                              <!-- <div class="col-1 email-simbol-send">@</div> -->
                              <div class="col-4">
                                <div class="input-group domain">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">@</span>
                                  </div>
                                  <select-input
                                    label="domain"
                                    :reduce="(option) => option.domain"
                                    v-model="form.domain"
                                    :options="domains"
                                    :clearable="false"
                                    class="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="alert alert-warning" v-else>
                              {{$t('warnings.send')}}
                              <router-link to="/mail/domains" class="to-right"
                                ><span>{{$t('generic-str.register')}}</span></router-link
                              >
                            </div>
                          </div>
                        </div>
                        <!--
                        <div class="form-group row" v-if="form.type == 'file'">
                          <label class="control-label col-12"
                            >Adicionar Arquivo</label
                          >
                          <div v-if="!importing" class="col-md-9">
                            <input
                              ref="uploadedFile"
                              type="file"
                              accept=".csv"
                              hidden
                              @change="uploadFile"
                            />
                            <div class="input-group mb-3">
                              <div class="form-control">{{ form.file }}</div>
                              <div class="input-group-append">
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  @click="openFileUpload"
                                >
                                  <template v-if="form.file"
                                    >Trocar Arquivo</template
                                  >
                                  <template v-else>Selecionar Arquivo</template>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div v-else class="col-md-9">
                            <div class="row">
                              <div class="col-12">
                                <small>O arquivo está sendo validado</small>
                              </div>
                            </div>
                            <div class="progress">
                              <div
                                class="
                                  progress-bar
                                  progress-bar-striped
                                  progress-bar-animated
                                "
                                role="progressbar"
                                :style="`width: ${uploadProgress}%;`"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {{ uploadProgress }}%
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <a
                              href="/exemplos/example_mail.csv"
                              class="btn btn-bulk btn-success w-100p"
                              v-tooltip.top="
                                'Layout do arquivo para importação.'
                              "
                              >Exemplo</a
                            >
                          </div>
                          <hr />
                        </div>
                        -->
                        <div class="form-group" v-if="form.type == 'file'">
                          <multi-upload
                            :allowed-extensions="['csv']"
                            :max-files="10"
                            :upload="uploadCurrentFile"
                            :validate="validateCurrentFile"
                            :complete="uploadCompleted"
                            :tip="$t('multi-upload.title')"
                            ref="uploader"
                          />
                          <!-- <div v-if="uploader !== null">
                            <div>uploader.uploading {{uploader.uploading}}</div>
                            <div>uploader.hasFiles {{uploader.hasFiles}}</div>
                            <div>uploader.completed {{uploader.completed}}</div>
                          </div> -->
                          <div class="row justify-content-end">
                            <div class="col-6 col-md-3">
                              <a
                                href="/exemplos/example_mail.csv"
                                class="btn btn-bulk btn-success w-100p"
                                v-tooltip.top="$t('sms.send-msg.tabs.archives.lbl-tooltip-archive')"
                              >
                                <i class="fas fa-download"></i>
                                {{$t('generic-str.example')}}
                              </a>
                            </div>
                            <div class="col-6 col-md-3" v-if="uploader && uploader.hasFiles">
                              <button
                                class="btn btn-primary btn-block"
                                :disabled="form.type === 'file' && (!uploader || !uploader.done)"
                                @click.prevent="uploadAll"
                              >
                                <i class="fas fa-upload"></i>
                                Upload
                              </button>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <email-input
                          v-model="form.body"
                          :normalize="form.normalize"
                          :variables="vars"
                        />
                        <div class="form-group row" style="display: none">
                          <label
                            class="
                              control-label
                              text-left
                              col-md-12
                              text-left-m
                            "
                            >{{$t('generic-str.attachment')}}:</label
                          >
                          <div class="col-md-6">
                            <div class="custom-file">
                              <div class="row no-margin">
                                <div class="col-lg-7 col-md-7">
                                  <input
                                    type="file"
                                    @change="processFile($event)"
                                    class="custom-file-input"
                                    required
                                    id="validatedCustomIconLabel"
                                    role="button"
                                  />
                                  <label
                                    class="custom-file-label btn-icon"
                                    for="validatedCustomIconLabel"
                                    >{{ file_name }}</label
                                  >
                                </div>
                              </div>
                              <div class="invalid-feedback"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12">
                  <div class="card card-border-info">
                    <div class="card-header p-l-20 p-b-15 p-t-15">{{$t('options-div.title')}}</div>
                    <div class="card-body">
                      <div class="form-group row">
                        <div class="col-md-12">
                          <div
                            class="
                              custom-control
                              custom-checkbox
                              custom-control-inline
                              custom-control-inline-send
                            "
                          >
                            <input
                              class="tgl tgl-light tgl-primary calendar-change"
                              id="schedule"
                              type="checkbox"
                              v-model="form.schedule"
                              name="schedule"
                            />
                            <label class="tgl-btn" for="schedule"></label>
                            <label class="label-send-check">{{$t('options-div.lbl-schedule')}}</label>
                          </div>
                          <date-picker
                            v-if="form.schedule"
                            v-model="form.datetime"
                            :config="dateOptions"
                            class="form-control input-date"
                            :placeholder="$t('generic-str.date-hour')"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          class="control-label m-text-left col-lg-12 col-md-12"
                          >{{$t('generic-str.sender-name')}}</label
                        >
                        <div class="col-md-12 col-sm-12">
                          <input
                            v-model="form.from_name"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          class="control-label m-text-left col-lg-12 col-md-12"
                          >{{$t('generic-str.respons-email')}}</label
                        >
                        <div class="col-md-12 col-sm-12">
                          <input
                            v-model="form.reply_email"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <hr class="dashed" />
                      <template-input
                        v-model="form.template"
                        @input="insertTemplate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer bg-light">
          <button
            class="btn btn-success"
            v-modal="{ target: 'preview-modal', data: form.body }"
            @click.prevent="mountPreview"
          >
            <i class="fas fa-eye color-white"></i> Preview
          </button>
          <button
            class="btn btn-success"
            style="margin-left: 15px"
            :modal="{ target: 'send-test-modal', data: form }"
            @click="mountTest"
          >
            {{$t('generic-str.send-test')}}
          </button>
          <!-- <button
            id="msgSend"
            @click="send"
            type="button"
            :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
            :disabled="isSending || importing"
            class="btn btn-primary float-right"
          >
            {{$t('generic-str.send')}}
          </button> -->
          <button
            id="msgSend"
            @click="send"
            type="button"
            :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
            :disabled="form.type === 'file' && (!uploader || !uploader.completed)"
            class="btn btn-primary float-right"
          >
            {{$t('generic-str.send')}}
          </button>
        </div>
      </div>
    </div>
    <create-contact-modal id="create-contact" ref="create-contact"></create-contact-modal>
    <create-group-modal id="create-group" ref="create-group"></create-group-modal>
    <group-send-modal id="group-send-modal" ref="group-send-modal"></group-send-modal>
    <contact-send-modal id="contact-send-modal" ref="contact-send-modal"></contact-send-modal>
    <preview-modal id="preview-modal" ref="preview-modal"></preview-modal>
    <send-test-modal id="send-test-modal" ref="send-test-modal"></send-test-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from '@/plugins/axios';
import MailService from '@/services/mail.service';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import CreateContactModal from '@/components/contacts/CreateContactModal.vue';
import PreviewModal from '@/components/mails/PreviewModal.vue';
import GroupSendModal from '@/components/groups/GroupSendModal.vue';
import ContactSendModal from '@/components/contacts/ContactSendModal.vue';
import SendTestModal from '@/components/mails/SendTestModal.vue';
// import GroupTable from '@/components/groups/GroupTable.vue';
import CreateGroupModal from '@/components/groups/CreateGroupModal.vue';
import EmailInput from '@/components/form/EmailInput.vue';
import Swal from 'sweetalert2';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Emojis from '@/components/Emojis.vue';
import SelectInput from '@/components/SelectInput.vue';
import TemplateInput from '@/components/mails/TemplateInput.vue';
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';
import VueTagsInput from '@johmun/vue-tags-input';
import MultiUpload from '@/components/MultiUpload.vue';

export default {
  name: 'SendMail',
  components: {
    SendTestModal,
    PageHeader,
    PreviewModal,
    CreateContactModal,
    ContactSendModal,
    GroupSendModal,
    CreateGroupModal,
    Emojis,
    SelectInput,
    datePicker,
    TemplateInput,
    VueTagsInput,
    EmailInput,
    MultiUpload,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      products: [],
      selectedProduct: {},
      preview: false,
      domains: [],
      tags: [],
      file_name: null,
      shortCodes: [],
      addVars: false,
      addVarsSubject: false,
      hasVar: false,
      groups: [],
      isSending: false,
      contacts: [],
      files: [],
      dateOptions: {
        format: 'DD/MM/YYYY HH:mm',
        minDate: moment(),
        locale: 'pt',
        useCurrent: true,
        keepInvalid: true,
      },
      configEditor: {
        fullPage: true,
        allowedContent: true,
        extraPlugins: 'emoji',
      },
      selectedGroups: [],
      selectedContacts: [],
      selectedFiles: [],
      importing: false,
      uploadProgress: 0,
      uploader: null,
      vars: [],
      fileVariables: [],
      form: {
        productId: '',
        type: 'simple',
        from: '',
        domain: '',
        to: '',
        body: '',
        from_name: '',
        placeholder: '',
        subject: '',
        reply_email: '',
      },
      unicode: false,
      replace: false,
      flash: false,
      segments: 1,
      submited: false,
      length: 0,
    };
  },
  computed: {
    classes() {
      return ['wizard'];
    },
  },
  created() {
    this.fetchDomains();
    this.fetchProducts();
  },
  methods: {
    uploadAll() {
      this.$refs.uploader.launch();
    },
    async readFile(file, encoding) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.fileVariables = [];
          const lines = reader.result.split(/\r\n|\n|\r/);
          const total = lines.length;

          // if (total > 30) {
          if (total > 1000001) {
            const error = `${this.$t('generic-str.archive')} ${file.name} ${this.$t('warnings.exceed')}}`;

            file.status = 'error';
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error,
              type: 'danger',
            });

            reject(error);
          } else {
            this.vars = lines.shift().split(';').map((variable) => ({
              value: variable,
              name: variable,
            }));

            resolve();
          }
        };
        reader.readAsText(file.source, encoding);
      });
    },
    async validateCurrentFile(file) {
      return this.readFile(file, 'UTF-8');
    },
    async uploadCurrentFile(file) {
      this.importing = true;

      const formData = new FormData();

      formData.append('file', file.source);

      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (progress > 5) file.progress = progress;
        },
      };

      return axios()
        .post('email/files', formData, config)
        .then(
          () => {
            file.status = 'completed';
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            file.status = 'error';
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    uploadCompleted() {
      // console.log('[this.$refs.filenames]', this.$refs.uploader.filenames);
      this.form.files = this.$refs.uploader.filenames;
    },
    fetchDomains() {
      MailService.getDomains({
        valid: true,
      }).then(
        (domains) => {
          this.loading = false;
          this.domains = domains;
          this.form.from = '';

          if (domains && domains.length) {
            this.form.domain = domains[0].domain;
          }
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchProducts() {
      MailService.getProducts().then(
        (products) => {
          this.products = products;
          if (products) {
            this.selectedProduct = products[0];
            this.form.productId = this.selectedProduct.id;
          }
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeProduct(product) {
      this.selectedProduct = product;
    },
    mountPreview() {
      this.$root.$emit('updated.previewmail', { body: this.form.body });
    },
    async mountTest() {
      if (this.form.subject === '') {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('warnings.send-component.subject'),
          type: 'danger',
        });
        return;
      }

      this.form.from = this.form.from_t;

      if (
        this.form.from === null ||
        this.form.from === '' ||
        this.form.from === undefined
      ) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('warnings.send-component.invalid-sender'),
          type: 'danger',
        });
        return;
      }

      if (this.domains.length > 0) {
        this.form.from = `${this.form.from_t}@${this.form.domain}`;
        if (!MailService.validateEmail(this.form.from)) {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: this.$t('warnings.send-component.invalid-sender'),
            type: 'danger',
          });
          return;
        }
      }

      if (this.form.datetime) {
        this.form.scheduled_at = moment(
          this.form.datetime,
          'DD/MM/YYYY HH:mm:ss',
        ).format('YYYY-MM-DD HH:mm:ss');
      }

      await this.$refs['send-test-modal'].show();

      this.$root.$emit('updated.testmail', {
        productId: this.form.productId,
        from: this.form.from,
        body: this.form.body,
        from_name: this.form.from_name,
        subject: this.form.subject,
        reply_email: this.form.reply_email,
      });
    },
    removeSpaces(event) {
      const el = event.target;
      el.value = el.value.replace(/\s+/g, '');
    },
    putEmoji(emoji) {
      if (this.form.subject.length) {
        this.form.subject += ` ${emoji}`;
      } else {
        this.form.subject = emoji;
      }
    },
    selectedGroupsChange(param) {
      param.selectedGroups.forEach((group) => {
        if (!this.selectedGroups.includes(group.id)) {
          this.groups.push(group);
          this.selectedGroups.push(group.id);
        }
      });
    },
    toBottom() {
      window.onscroll = () => {
        const bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop,
          ) +
            window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.scrolledToBottom = true; // replace it with your code
        }
      };
    },
    selectedContactsChange(param) {
      param.selectedContacts.forEach((contact) => {
        if (!this.selectedContacts.includes(contact.email)) {
          this.contacts.push(contact);
          this.selectedContacts.push(contact.id);
        }
      });
    },
    selectedFilesChange(param) {
      param.selectedFiles.forEach((file) => {
        this.selectedFiles[0] = file.name;
        this.files[0] = file;
        // if (!this.selectedFiles.includes(file.id)) {
        // this.files.push(file);
        // this.selectedFiles.push(file.id);
        // }
      });
    },
    changeType(type) {
      // console.log({ type });

      if (type === 'contacts' || type === 'groups') {
        this.vars = [
          { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
          { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
          { name: this.$t('generic-str.lbl-cellphone'), value: 'mobile_number' },
          { name: 'Email', value: 'email' },
        ];
      } else if (type === 'file') {
        this.vars = this.fileVariables;
        const check = setInterval(() => {
          // console.log('this.uploader', this.uploader);
          if (type !== 'file' || this.uploader !== null) clearInterval(check);
          if (this.$refs.uploader) this.uploader = this.$refs.uploader;
        }, 500);
      } else {
        this.vars = [];
      }
      this.form.type = type;
      this.submited = false;
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    uploadFile(event) {
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append('file', file);
      this.form.file = file.name;

      reader.onload = () => {
        self.fileVariables = [];
        const variables = reader.result
          .split('\n')
          .shift()
          .replace('\r', '')
          .split(';'); // first line
        variables.forEach((variable) => {
          self.fileVariables.push({
            value: variable,
            name: variable,
          });
        });
        self.vars = self.fileVariables;
      };
      reader.readAsText(file, 'UTF-8');

      const config = {
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
      };

      axios()
        .post('email/files', formData, config)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            self.form.file = '';
            self.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    removeGroup(i) {
      this.groups.splice(i, 1);
      this.selectedGroups.splice(i, 1);
      this.$root.$emit('groups.selected.refresh', {
        selectedGroups: this.groups,
      });
    },
    removeContact(i) {
      this.contacts.splice(i, 1);
      this.selectedContacts.splice(i, 1);
      this.$root.$emit('contacts.selected.refresh', {
        selectedContacts: this.contacts,
      });
    },
    removeFile(i) {
      this.files.splice(i, 1);
      this.selectedFiles.splice(i, 1);
    },
    addVar(entity, to) {
      if (to === 'subject') {
        this.addVarsSubject = false;
        this.form.subject = this.form.subject.concat(` {${entity}} `);
      } else {
        this.addVars = false;
        this.form.body = this.form.body.concat(` {${entity}} `);
      }
    },
    showAddVar() {
      this.addVars = true;
    },
    hideAddVar() {
      this.addVars = false;
    },
    insertTemplate(template) {
      this.form.body = template;
      this.countSegments();
      // form.body = e.target.value;
    },
    send() {
      if (this.form.datetime) {
        const scheduledAt = moment(
          this.form.datetime,
          'DD/MM/YYYY HH:mm:ss',
        ).format('YYYY-MM-DD HH:mm:ss');
        this.form.scheduled_at = scheduledAt;
      }
      if (this.form.subject === '') {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('warnings.send-component.subject'),
          type: 'danger',
        });
        return;
      }
      this.form.from = this.form.from_t;
      if (
        this.form.from === null ||
        this.form.from === '' ||
        this.form.from === undefined
      ) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('warnings.send-component.invalid-sender'),
          type: 'danger',
        });
        return;
      }

      if (this.domains.length > 0) {
        this.form.from = `${this.form.from}@${this.form.domain}`;
        if (!MailService.validateEmail(this.form.from)) {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: this.$t('warnings.send-component.invalid-sender'),
            type: 'danger',
          });
          return;
        }
      }
      this.submited = true;
      if (this.form.type === 'simple') {
        this.form.contacts = [];
        this.form.groups = [];
        if (this.tags.length > 0 && this.form.body.length > 0) {
          const toArray = [];
          this.tags.forEach((element) => {
            toArray.push(element.text);
          });
          this.form.to = toArray.join(', ');
          this.sendRequest();
        } else {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: this.$t('warnings.send-component.recipient'),
            type: 'danger',
          });
          return;
        }
      }
      if (this.form.type === 'contacts') {
        this.form.contacts = this.selectedContacts;
        this.form.to = '';
        if (this.form.contacts.length > 0 && this.form.body.length > 0) {
          this.sendRequest();
        } else {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: this.$t('warnings.send-component.field'),
            type: 'danger',
          });
          return;
        }
      }
      if (this.form.type === 'groups') {
        this.form.contacts = [];
        this.form.to = '';
        if (this.selectedGroups.length > 0 && this.form.body.length > 0) {
          this.form.groups = this.selectedGroups;
          this.sendRequest();
        } else {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: this.$t('warnings.send-component.group'),
            type: 'danger',
          });
          return;
        }
      }
      if (this.form.type === 'file') {
        if (this.form.files.length > 0 && this.form.body.length > 0) {
          this.sendRequest();
        } else {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: this.$t('warnings.send-component.archive'),
            type: 'danger',
          });
        }
      }
    },
    sendRequest() {
      Swal.fire({
        title: this.$t('generic-str.menu.send-msg'),
        text: this.$t('warnings.send-component.send-confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        this.toBottom();
        if (result.value) {
          this.isSending = true;
          MailService.sendMail(this.form)
            .then(
              (send) => {
                if (this.form.type === 'simple') {
                  this.$router.push({
                    name: 'MailSuccess',
                    params: { mail: send.data },
                  });
                } else {
                  this.$router.push('/mail/campaigns');
                }
                this.$toast.show({
                  title: this.$t('generic-str.success'),
                  content: this.$t('warnings.send-component.msg-sent'),
                  type: 'success',
                });
                this.isSending = false;
              },
              (error) => {
                this.$toast.show({
                  title: this.$t('sms.infos.filters.status.lbl-error'),
                  content: error.response.data.message,
                  type: 'danger',
                });
                this.isSending = false;
              },
            )
            .catch(() => {
              this.isSending = false;
            });
        }
      });
    },
  },
  mounted() {
    this.$root.$on('groups.addonsend', this.selectedGroupsChange);
    this.$root.$on('contacts.addonsend', this.selectedContactsChange);
    this.$root.$on('files.addonsend', this.selectedFilesChange);
  },
};
</script>

<style lang="scss">
.modal {
  z-index: 1000;
}

.modal-backdrop {
  z-index: 999;
}

i {
  color: inherit;
}

.input-group.domain {
  .v-select {
    padding: 0;
  }

  .vs__dropdown-toggle {
    border: none !important;
  }
}

textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}
.input-date {
  margin-top: 15px !important;
}
.form-control.invalid {
  border-color: #ff5c75;
}
.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #a6a6a6 !important;
}
.second-toolbar #logo {
  visibility: hidden;
}
.fr-wrapper > *:first-child {
  display: none;
}
.fr-wrapper .fr-element {
  display: block;
}
.send-group-card {
  box-shadow: none;
}
.send-group-card .table-placeholder i,
h5 {
  color: #b5b5b5;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.custom-control-inline-send {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.label-send-check {
  margin-left: 10px;
  margin-top: 2px;
}
.ul-list-groups {
  margin: 0px;
  padding: 0px;
}
.ul-list-groups li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.subjectvar {
  position: absolute !important;
  top: 6px;
  right: 55px;
}
.ti-input {
  border-color: #dfe7f3 !important;
  border: 1px solid #dfe7f3 !important;
  width: 100%;
  border-radius: 0.25rem !important;
}
.vue-tags-input {
  max-width: 100% !important;
  width: 100% !important;
}
#cke_673 {
  display: none !important;
}
.cke_toolbar_last {
  display: none;
}
</style>
